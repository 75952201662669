@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "BasierCircleRegular";
  src: local("Basier Circle Regular"), local("BasierCircle-Regular"),
    url("../src/assets/fonts/BasierCircle-Regular.woff2") format("woff2"),
    url("../src/assets/fonts/BasierCircle-Regular.woff") format("woff"),
    url("../src/assets/fonts/BasierCircle-Regular.ttf") format("truetype")
      url("../src/assets/fonts/BasierCircle-Regular.eot")
      format("embedded-opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BasierCircleSemiBold";
  src: local("Basier Circle SemiBold"), local("BasierCircle-SemiBold"),
    url("../src/assets/fonts/BasierCircle-SemiBold.woff2") format("woff2"),
    url("../src/assets/fonts/BasierCircle-SemiBold.woff") format("woff"),
    url("../src/assets/fonts/BasierCircle-SemiBold.ttf") format("truetype")
      url("../src/assets/fonts/BasierCircle-SemiBold.eot")
      format("embedded-opentype");

  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "BasierCircleMedium";
  src: local("Basier Circle Medium"), local("BasierCircle-Medium"),
    url("../src/assets/fonts/BasierCircle-Medium.woff2") format("woff2"),
    url("../src/assets/fonts/BasierCircle-Medium.woff") format("woff"),
    url("../src/assets/fonts/BasierCircle-Medium.ttf") format("truetype")
      url("../src/assets/fonts/BasierCircle-Medium.eot")
      format("embedded-opentype");

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

* {
  font-size: 100%;
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "BasierCircleRegular", sans-serif !important;
  min-height: 100vh;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html {
  height: 100% !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "HelveticaNeueExtended", "HelveticaNeue", Helvetica, sans-serif !important;
  font-weight: 500;
}

p {
  margin: 0;
}

.backdrop {
  background: rgba(0, 0, 0, 0.4) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  backdrop-filter: blur(5px) !important;
}

/* Start of Custom Loader */
.loading {
  position: relative;
}

.loading:after {
  content: ".";
  position: absolute;
  right: "-5px";
  animation: dots 1.5s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

/* End of Custom Loader */

:root {
  --bani-green: #02ba81;
  --bani-grey2: #c1c1c1;
  --bani-grey: #65717c;
  --bani-grey3: #e1e1e1;
  --bani-yellow: #f2c94c;
  --bani-warning: #ffb413;
  --bani-red: #f3564d;
  --bani-blue: #5444f2;
  --bani-blue2: #2d9cdb;
}

/* custom styling */
.text-green {
  color: var(--bani-green);
}

input[type="date"].grey-placeholder,
input[type="text"].grey-placeholder ::placeholder,
select.grey-placeholder {
  color: var(--bani-grey2);
}

.h-44 {
  height: 44px;
}

.dashboard-header {
  position: fixed;
  background: #fff;
  z-index: 100;
}

.dashboard-content {
  margin-top: 80px;
}

.badge {
  height: 8px;
  width: 8px;
  border-radius: 4px;
}

.yellow {
  background-color: var(--bani-yellow);
}

.warning-text {
  color: var(--bani-warning);
}

.warning {
  background-color: var(--bani-warning);
}

.grey3 {
  background-color: var(--bani-grey3);
}

.green {
  background-color: var(--bani-green);
}

.red {
  background-color: var(--bani-red);
}

.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: none;
}

.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: none;
}

table.striped tbody tr:nth-of-type(even) {
  background: rgba(245, 246, 250, 0.7);
}

table th {
  font-weight: 300;
  color: var(--bani-grey);
}

table td {
  font-weight: 300;
  vertical-align: center;
  height: fit-content;
}

table,
tr {
  border-spacing: 0;
  border-collapse: separate;
}

th {
  padding: 10px 10px 30px;
}

.helv-regular {
  font-family: "HelveticaNeue";
  font-weight: 400;
  letter-spacing: 0.2px;
}

.helv-medium {
  font-family: "HelveticaNeue";
  font-weight: 500;
}

.product-row .remove-icon {
  transition: 0.4s ease;
  opacity: 0;
}

.product-row:hover .remove-icon {
  opacity: 1;
}

.css-1s2u09g-control {
  height: 44px;
}

/* toast */
.bani-toast {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.input_error_msg {
  margin-top: 8px !important;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

main.dashboard-content::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 10px;
}

main.dashboard-content::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
}

.activeCheckIcon path {
  fill: #02ba81;
}

/* apexcharts custom style*/
.apexcharts-canvas path:active,
.apexcharts-canvas path:focus,
.apexcharts-canvas path:active,
.apexcharts-canvas path:focus-visible,
.apexcharts-canvas path:focus-within,
.apexcharts-canvas path:target,
.apexcharts-canvas path:visited,
.apexcharts-canvas path:hover {
  fill: #5444f2;
}

.apexcharts-text tspan {
  font-size: 10px;
}

.apexcharts-text.apexcharts-datalabel-label {
  font-size: 22px;
}

.apexcharts-text.apexcharts-datalabel-value {
  font-size: 14px !important;
  transform: translateY(-10px);
}

.apexcharts-bar-area {
  position: relative !important;
}

/* Apex charts tooltips custom styles */
.inflow-chart .apexcharts-tooltip,
.outflow-chart .apexcharts-tooltip {
  color: #fff;
  transform: translateX(-50%) translateY(-20px);
  overflow: visible !important;
  white-space: normal !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.inflow-chart .apexcharts-tooltip,
.outflow-chart .apexcharts-tooltip span {
  padding: 5px 10px;
}

.apex_custom_tooltip {
  padding: 3px 10px;
  background-color: #000000;
  box-shadow: 0px 4px 4px rgba(225, 225, 225, 0.53);
  color: #f5f6fa;
  position: relative;
  border: none;
  border-radius: 3.5px !important;
  z-index: 99999999;
  white-space: nowrap;
}

.apex_custom_tooltip:before {
  /* For arrow bottom */
  right: calc(50% - 4px);
  bottom: -20%;
  /* For arrow right */
  /* right: 100%;
  top: calc(50% - 4px); */
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  /* margin-top: -40px; */
}

.apex_custom_tooltip:before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000000;
}

/* react-date-range custom styles */
.rdrDateRangePickerWrapper,
.rdrMonth,
.rdrCalendarWrapper {
  width: 100%;
}

.rdrDefinedRangesWrapper,
.rdrDateDisplayWrapper {
  display: none;
  position: absolute;
}

.css-26l3qy-menu {
  z-index: 100 !important;
  top: -100% !important;
}

.hidden-select {
  opacity: 0;
  position: absolute;
}

.blue-svg path {
  stroke: var(--bani-blue);
}

/* phone input */
.mobile_money_phone .PhoneInputInput {
  outline: none;
}

.mobile_money_phone .PhoneInputCountryIcon {
  height: 22px;
  width: 22px;
  border-radius: 11px;
  overflow: hidden;
  box-shadow: none;
}

.mobile_money_phone .PhoneInputCountryIcon img {
  height: 100%;
  width: 100%;
}

.general-input-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: block;
  color: #65717c;
}

.searchable-select-component .css-14el2xx-placeholder {
  color: #c1c1c1;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.unapproved-navlinks {
  cursor: not-allowed;
}

.business-link {
  width: calc(100% - 10px);
}

.h-with-test-banner {
  min-height: calc(100vh - 30px);
}

.app-update-badge {
  position: relative;
  width: fit-content;
}

#HW_badge_cont {
  position: absolute !important;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
}

#HW_badge {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 5px;
  right: 0;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
}

#HW_frame_cont {
  margin-left: -15px;
  margin-top: 5px;
}

.mobile-money-phone-input select {
  pointer-events: none;
}

.mobile-money-phone-input .PhoneInputCountrySelectArrow {
  display: none;
}

.HelveticaNeueCyr {
  font-family: "HelveticaNeueCyr", sans-serif !important;
}

.display-is-list path {
  stroke: var(--bani-blue);
}

.activity-feed-table .rdt_Table,
.no-padding-table .rdt_Table {
  padding: 0 !important;
}

.activity-feed-table .rdt_TableCol {
  font-size: 12px !important;
  height: 33px !important;
}

.activity-feed-table .rdt_TableHeadRow {
  min-height: 33px;
  border-bottom: none;
}

.arrow-path-stroke-blue path {
  stroke: #5444f2;
}

@media (min-width: 768px) {
  .whatsapp-notifications-toggler {
    margin-left: -1px !important;
  }

  .va-overview-list .rdt_TableBody {
    max-height: 480px;
    overflow-y: auto;
  }

  .va-overview-list-wrapper > .iibGpm {
    padding-bottom: 0;
  }

  .va-overview-list .rdt_TableBody::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }

  .va-overview-list .rdt_TableBody .rdt_TableRow {
    flex-shrink: 0;
  }
}

.MuiPaper-elevation8 {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}

#simple-popover {
  z-index: 9998;
}

.hidden-sidenav {
  z-index: 999;
}

/* toasts should have the highest zindex which is 9999 */

/* Flyer design styles */
@media screen {
  .flyer-design {
    display: none;
  }
}

.sidenav-icon path {
  stroke: currentColor !important;
}

.grey-icon path {
  stroke: var(--bani-grey);
}
